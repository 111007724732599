<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="12">
              <a-form-item label="注射人电话">
                <a-input placeholder="请输入注射人电话" v-model="queryParam.telephone" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="12">
              <a-form-item label="注射人姓名">
                <a-input placeholder="请输入注射人姓名" v-model="queryParam.realName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="12">
              <a-form-item label="注射地区">
                <a-cascader
                  :options="regionList"
                  placeholder="省-市-区(县)"
                  :field-names="{label: 'label', value: 'value', children: 'children' }"
                  :value="defaultRegion"
                  @change="changeRegion"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="12">
              <a-form-item label="注射日期">
                <a-date-picker
                  v-model="queryParam.injectDate"
                  valueFormat="YYYY-MM-DD"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="12">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <!--a-button type="primary" icon="plus">添加</a-button-->
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
      </s-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import { regionList } from '@/api/region'
import { vaccinesRecordList } from '@/api/vaccines'
const columns = [
  {
    title: '记录ID',
    dataIndex: 'keyId'
  },
  {
    title: '注射人姓名',
    dataIndex: 'realName'
  },
  {
    title: '注射人手机号',
    dataIndex: 'telephone'
  },
  {
    title: '注射地址',
    dataIndex: 'injectAddress'
  },
  {
    title: '疫苗厂商',
    dataIndex: 'manufacture'
  },
  {
    title: '注射日期',
    dataIndex: 'injectDate'
  },
  {
    title: '添加时间',
    dataIndex: 'createTime'
  }
]

export default {
  name: 'RecordList',
  components: {
    STable,
    Ellipsis,
    STree
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addRoleFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        realName: '',
        telephone: '',
        injectDate: '',
        provinceId: 0,
        cityId: 0,
        areaId: 0
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return vaccinesRecordList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      regionList: [],
      defaultRegion: []
    }
  },
  filters: {
  },
  created () {
    this.regionListApi()
  },
  methods: {
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    resetSearchForm () {
      this.defaultRegion = []
      this.queryParam = {
        realName: '',
        telephone: '',
        injectDate: '',
        provinceId: 0,
        cityId: 0,
        areaId: 0
      }
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.queryParam.provinceId = e[0] ? e[0] : 0
      this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaId = e[2] ? e[2] : 0
    }
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  }
}
</script>
