import request from '@/utils/request'
const baseURL = '/baseapi'
const VaccinesApi = {
  VaccinesRecordList: '/admin/vaccines/recordList'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function vaccinesRecordList (parameter) {
  return request({
    baseURL: baseURL,
    url: VaccinesApi.VaccinesRecordList,
    method: 'post',
    data: parameter
  })
}
